import { CustomElement, parse } from "@nxcode-npm/custom_element";
import { app_config } from "../../app_config";
import { Router } from "@nxcode-npm/app_router";
import { addDoc, collection } from "firebase/firestore";
import { App } from "../../app";
export class InsertJob extends CustomElement {
    constructor() {
        super();
        this.refs = {
            icon_close: null,
            btn_confirm: null,
            form_container: null,
            loader: null,
            loading: null,
            loaded: null
        };
        this.place = {};
        this.admin = typeof Router.router["insert_job"].params === "object" ?
            Router.router["insert_job"].params["admin"] : false;
        //console.debug("admin", this.admin)
        if (!this.admin)
            this.setAnalytics("job_enter");
    }
    render() {
        super.render();
        this.setRefs();
        this.setEventListeners();
    }
    template() {
        return [`<nav class="navbar bg-light fixed-top border-bottom" data-bs-theme="light">
                        <div class="container-fluid">
                            <span class="navbar-brand">${app_config.appName}</span>
                            <i ref="icon_close" class="bi bi-x-lg fw-bold fs-6"></i>
                        </div>
                 </nav>       
                        `, this.renderContent()];
    }
    renderContent() {
        const content = document.createElement("main");
        content.classList.add("h-100", "pt-4", "px-3");
        const formContainer = document.createElement("div");
        formContainer.setAttribute("ref", "form_container");
        formContainer.append(parse(`<div class="fs-6 mb-2">Trova attivit&agrave; su Google Maps</div>`), this.renderAutocomplete(), parse(`<button ref="btn_confirm" class="btn btn-danger my-3"><i class="bi bi-geo-fill"></i> Conferma</button>`));
        content.append(formContainer, parse(`<div ref="loader" class="fs-6" style="display:none">
            <div ref="loading">Attendi per favore...</div>
            <div ref="loaded" style="display:none"></div>
        </div>`));
        return content;
    }
    renderAutocomplete() {
        const container = document.createElement("div");
        const inputAutocomplete = document.createElement("map-autocomplete");
        inputAutocomplete.setAttribute("placeholder", `Es. Pizzorante Pinco Pallino`);
        inputAutocomplete.types = ["establishment"];
        inputAutocomplete.fields = ["formatted_address", "formatted_phone_number", "website", "name", "geometry", "place_id", "type"];
        container.append(inputAutocomplete);
        return container;
    }
    setEventListeners() {
        this.refs.icon_close.onclick = () => Router.nav({ key: "index" });
        this.addEventListener("autocomplete_place_changed", (ev) => {
            this.place = ev.detail;
            console.debug(this.place);
        });
        this.refs.btn_confirm.onclick = () => {
            if (this.place.formatted_address) {
                this.refs.form_container.style.display = "none";
                this.refs.loader.style.display = "block";
                addDoc(collection(App.state.db, "jobs"), this.getData()).then(docRef => {
                    console.log("Document written with ID: ", docRef.id);
                    this.refs.loading.style.display = "none";
                    this.refs.loaded.style.display = "block";
                    this.refs.loaded.innerHTML = `<i class="bi bi-check-circle-fill"></i> ${this.place.name} &egrave; stato aggiungo alla mappa`;
                    if (!this.admin)
                        this.setAnalytics("insert_job");
                }).catch(err => console.error(err));
            }
        };
    }
    getData() {
        var _a, _b, _c, _d;
        const data = {
            name: this.place.name,
            place_id: this.place.place_id,
            address: this.place.formatted_address,
            phone_number: this.place.formatted_phone_number || ``,
            web_site: this.place.website || ``,
            lat: (_b = (_a = this.place.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat(),
            lng: (_d = (_c = this.place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lng(),
            type: this.place.types ? this.place.types[0] : []
        };
        return data;
    }
    setAnalytics(action) {
        if (!this.admin) {
            const data = { action: action, timestamp: new Date(Date.now()).toISOString() };
            addDoc(collection(App.state.db, "analytics_"), data).then(docRef => { console.log(data); })
                .catch(err => console.error(err));
        }
    }
}
InsertJob.root_name = `insert_job`;
