import * as bs from "bootstrap";

import { IndexPage } from "./src/pages/index/index";
import { Router, RouterElement } from "@nxcode-npm/app_router";
import { app_router } from "./src/app_router";
import { Navbar } from "./src/components/navbar/navbar";
import { MapAutocomplete } from "./src/components/map-autocomplete/map_autocomplete";
import { MapJobs } from "./src/pages/map_jobs/map_jobs";
import { InsertJob } from "./src/pages/insert_job/insert_job";
import { AdminPage } from "./src/pages/admin/admin";

import "./src/styles/styles.scss"

customElements.define("index-page", IndexPage)
customElements.define("router-element", RouterElement)
customElements.define("nav-bar", Navbar)
customElements.define("map-autocomplete", MapAutocomplete)
customElements.define("map-jobs", MapJobs)
customElements.define("insert-job", InsertJob)
customElements.define("admin-page", AdminPage)

Router.initialize(app_router)


