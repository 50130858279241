import { CustomElement } from "@nxcode-npm/custom_element";
import { app_config } from "../../app_config";
import { Router } from "@nxcode-npm/app_router";
export class Navbar extends CustomElement {
    constructor() {
        super();
        this.refs = {
            nav_expl: null,
            nav_insert: null
        };
    }
    render() {
        super.render();
        this.setRefs();
        this.setEventListeners();
    }
    template() {
        return [`<nav class="navbar fixed-top bg-light border-bottom border-body" data-bs-theme="light">
                        <div class="container-fluid">
                            <span class="navbar-brand"></span>
                            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <!-- OFFCANVAS -->
                            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel"><img src="logo.png" with="48" height="48" /> ${app_config.appName}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item" ref="nav_expl">
            <a class="nav-link" aria-current="page" href="#"><i class="bi bi-compass-fill"></i> Esplora la mappa</a>
          </li>
          <li class="nav-item" ref="nav_insert">
            <a class="nav-link" aria-current="page" href="#"><i class="bi bi-geo-fill"></i> Aggiungi un <i>pin</i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="#"><small>Contact us:<br/><span class="text-danger">info@snap-job.me</span></small></a>
          </li>
        </ul>
      </div>
      </div>
      <!-- END OFFCANVAS -->      
    </div>
    </nav>`];
    }
    setEventListeners() {
        this.refs.nav_expl.onclick = (ev) => {
            ev.preventDefault();
            Router.nav({ key: "map_jobs" });
        };
        this.refs.nav_insert.onclick = (ev) => {
            ev.preventDefault();
            Router.nav({ key: "insert_job" });
        };
    }
}
