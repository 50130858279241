export const app_router = {
    "index": {
        customElement: "index-page",
        container() {
            return document.querySelector("router-element");
        },
    },
    "map_jobs": {
        customElement: "map-jobs",
        name: "map",
        container() {
            return document.querySelector("router-element");
        },
    },
    "insert_job": {
        customElement: "insert-job",
        name: "insert-job",
        container() {
            return document.querySelector("router-element");
        },
    },
    "admin": {
        customElement: "admin-page",
        container() {
            return document.querySelector("router-element");
        },
    }
};
