import { CustomElement } from "@nxcode-npm/custom_element";
//npm install --save-dev @types/googlemaps
var autocomplete;
export class MapAutocomplete extends CustomElement {
    constructor() {
        super();
        this.refs = {
            btn_confirm: null,
            autocomplete_input: null
        };
        this.types = [];
        this.fields = [];
        this.placeChanged = () => {
            this.placeResult = autocomplete.getPlace();
            this.dispatchEvent(new CustomEvent("autocomplete_place_changed", { bubbles: true, detail: this.placeResult }));
        };
    }
    render() {
        super.render();
        this.setRefs();
        this.initAutocomplete();
    }
    template() {
        return [`<div class="input-group mb-3">
            <span class="input-group-text"><i class="bi bi-geo-alt-fill"></i></span>
            <input ref="autocomplete_input" type="text" class="form-control" placeholder="${this.getAttribute("placeholder") || ``}" />
            </div>`];
    }
    initAutocomplete() {
        autocomplete = new google.maps.places.Autocomplete(this.refs.autocomplete_input, {
            componentRestrictions: { country: ["it"], },
            //types:["administrative_area_level_2"],
            //fields: ["formatted_address", "name", "geometry", "place_id"]
            types: this.types,
            fields: this.fields
        });
        this.refs.autocomplete_input.focus();
        autocomplete.addListener("place_changed", this.placeChanged);
    }
}
