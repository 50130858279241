export const app_config = {
    appName: `Snapjob`,
    admin: "giampa?",
    email: "info@snap-job.me",
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    firebaseConfig: {
        apiKey: "AIzaSyDHHNVwGZD9iRw4N7OJkbXas76MVvMDVlg",
        authDomain: "cityjob-2531a.firebaseapp.com",
        projectId: "cityjob-2531a",
        storageBucket: "cityjob-2531a.firebasestorage.app",
        messagingSenderId: "108578518884",
        appId: "1:108578518884:web:3f78878a3608f2bae0ec71"
    }
};
