// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%;
}

body {
  overflow-y: auto;
  padding-top: 48px;
}

/* Set the size of the div element that contains the map */
#map {
  height: 400px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}`, "",{"version":3,"sources":["webpack://./src/styles/styles.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,iBAAA;AAAJ;;AAGA,0DAAA;AACA;EACI,aAAA,EAAA,6BAAA;EACA,WAAA,EAAA,2CAAA;AAAJ","sourcesContent":["@import \"~bootstrap-icons/font/bootstrap-icons.css\";\r\n\r\nhtml, body {\r\n    height: 100%;\r\n}\r\n\r\nbody {\r\n    overflow-y: auto;\r\n    padding-top: 48px;\r\n}\r\n\r\n/* Set the size of the div element that contains the map */\r\n#map {\r\n    height: 400px; /* The height is 400 pixels */\r\n    width: 100%; /* The width is the width of the web page */\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
