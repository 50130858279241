var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CustomElement } from "@nxcode-npm/custom_element";
import { app_config } from "../../app_config";
import { query, getDocs, collection, deleteDoc } from "firebase/firestore";
import { App } from "../../app";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { parse } from "@nxcode-npm/custom_element";
import { Router } from "@nxcode-npm/app_router";
export class AdminPage extends CustomElement {
    constructor() {
        super();
        this.refs = {
            btn_login: null,
            input_psw: null,
            login_form: null,
            loader: null,
            job_list: null,
            btn_insertJob: null,
            btn_goMap: null,
            actions_container: null
        };
        this.isLogged = false;
        this.initFirebaseApp();
    }
    render() {
        super.render();
        this.setRefs();
        this.setEventListeners();
    }
    template() {
        return [`<nav class="navbar bg-primary fixed-top" data-bs-theme="dark">
                        <div class="container-fluid">
                            <span class="navbar-brand">Admin</span>
                        </div>
                 </nav>`,
            `<main class="mx-2 my-3"> 
                 <div ref="login_form">   
                    <input ref="input_psw" type="password" class="form-control mb-3" aria-describedby="password">
                    <button  ref="btn_login" class="btn btn-danger mb-3">Login</button>
                 </div> 
                 <div ref="loader" style="display:none">Loading...</div> 
                 <div class="my-3 d-none" ref="actions_container">
                    <button type="button" ref="btn_insertJob" class="btn btn-primary">
                        &gt;insert_job
                    </button>
                    <button type="button" ref="btn_goMap" class="btn btn-primary">
                        &gt;map_jobs
                    </button>
                 </div>
                 <div ref="job_list"></div>
                 </main>`];
    }
    login() {
        if (this.refs.input_psw.value === app_config.admin) {
            this.showList();
        }
    }
    setEventListeners() {
        this.refs.btn_login.onclick = () => this.login();
        this.refs.btn_insertJob.onclick = () => {
            Router.nav({ key: "insert_job", params: { admin: true } });
        };
        this.refs.btn_goMap.onclick = () => {
            Router.nav({ key: "map_jobs", params: { admin: true } });
        };
    }
    initFirebaseApp() {
        // Initialize Firebase
        const app = initializeApp(app_config.firebaseConfig);
        // Initialize Cloud Firestore and get a reference to the service
        //console.debug(app)
        App.state.db = getFirestore(app);
        //console.debug("db", (<State>App.state).db)
    }
    showList() {
        return __awaiter(this, void 0, void 0, function* () {
            this.refs.actions_container.classList.toggle("d-none");
            this.refs.job_list.innerHTML = "";
            this.refs.job_list.style.display = "block";
            this.refs.login_form.style.display = "none";
            this.refs.loader.style.display = "block";
            const collRef = collection(App.state.db, "jobs");
            const q = query(collRef);
            const querySnapshot = yield getDocs(q);
            this.refs.loader.style.display = "none";
            if (querySnapshot.size > 0) {
                querySnapshot.forEach((doc) => {
                    this.refs.job_list.append(this.jobRow(doc));
                });
            }
            else {
                this.refs.job_list.append(parse(`<p>0 results</p>`));
            }
        });
    }
    jobRow(doc) {
        const container = document.createElement("div");
        container.classList.add("fs-6");
        const button = document.createElement("button");
        button.classList.add("btn", "btn-primary");
        button.onclick = () => this.deleteJob(doc.ref);
        button.innerText = "Elimina";
        container.append(parse(`<div><div>${doc.id}</div><div>${doc.data().name}</div><div>${doc.data().address}</div></div>`), button, parse(`<hr/>`));
        return container;
    }
    deleteJob(doc) {
        return __awaiter(this, void 0, void 0, function* () {
            this.refs.loader.style.display = "block";
            this.refs.job_list.style.display = "none";
            yield deleteDoc(doc);
            this.showList();
        });
    }
}
