var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CustomElement } from "@nxcode-npm/custom_element";
import { app_config } from "../../app_config";
import { Router } from "@nxcode-npm/app_router";
import { query, getDocs, collection, addDoc } from "firebase/firestore";
import { App } from "../../app";
export class MapJobs extends CustomElement {
    constructor() {
        super();
        this.refs = {
            icon_close: null,
        };
        this.placeIds = [];
        this.admin = typeof Router.router["map_jobs"].params === "object" ?
            Router.router["map_jobs"].params["admin"] : false;
        //this.setAnalytics()
    }
    setAnalytics() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.admin) {
                const data = { action: "map_enter", timestamp: new Date(Date.now()).toISOString() };
                const docRef = yield addDoc(collection(App.state.db, "analytics_"), data);
            }
        });
    }
    render() {
        const _super = Object.create(null, {
            render: { get: () => super.render }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.render.call(this);
            this.setRefs();
            this.setEventListeners();
            try {
                //await this.setAnalytics()
                yield this.displayMap();
                yield this.getJobMarkers();
            }
            catch (err) {
                console.error(err);
                document.getElementById("map").innerText = err;
            }
        });
    }
    template() {
        return [`<nav class="navbar bg-light fixed-top" data-bs-theme="light">
                        <div class="container-fluid">
                            <span class="navbar-brand">${app_config.appName}</span>
                            <i ref="icon_close" class="bi bi-x-lg fw-bold fs-6"></i>
                        </div>
                 </nav>       
                        `, `<main><div id='map'></div></main>`];
    }
    setEventListeners() {
        this.refs.icon_close.onclick = () => Router.nav({ key: "index" });
    }
    displayMap() {
        return __awaiter(this, void 0, void 0, function* () {
            // Request needed libraries.
            //npm i -D @types/google.maps
            const { Map, InfoWindow } = yield google.maps.importLibrary("maps");
            // Create an info window to share between markers.
            this.infoWindow = new InfoWindow();
            // The location of Brescia
            const position = { lat: 45.543, lng: 10.141 };
            let mapOptions = { center: position, zoom: 10, mapId: 'snap-job' };
            const container = document.getElementById("map");
            //container.style.height = `${screen.availHeight}px`
            this.map = new Map(container, mapOptions);
        });
    }
    getJobMarkers() {
        return __awaiter(this, void 0, void 0, function* () {
            const collRef = collection(App.state.db, "jobs");
            const q = query(collRef);
            const querySnapshot = yield getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                // check for duplicated doc
                const place_id = doc.data().place_id;
                if (this.placeIds.indexOf(place_id) != -1) {
                    console.log(`duplicated ${place_id} ${doc.data().name}`);
                }
                else {
                    // add marker
                    console.log(`add marker ${place_id} ${doc.data().name}`);
                    this.addMarker(doc.data());
                    // memo place_id
                    this.placeIds.push(place_id);
                }
            });
        });
    }
    addMarker(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { AdvancedMarkerElement, PinElement } = yield google.maps.importLibrary("marker");
            // A marker with a with a URL pointing to a PNG.
            const logoImg = document.createElement('img');
            logoImg.width = 38;
            logoImg.height = 38;
            logoImg.style.borderRadius = "50%";
            logoImg.style.border = "2px solid #aa203f";
            logoImg.src = 'logo.png';
            // A marker using a Font Awesome icon for the glyph.
            /*const icon = document.createElement('div');
            icon.innerHTML = '<i class="bi bi-person-exclamation fs-6"></i>';
            const biPin = new PinElement({
                glyph: icon,
                glyphColor: '#ffffff',
                background: '#d9534f',
                borderColor: '#d9534f',
            });*/
            const marker = new AdvancedMarkerElement({
                map: this.map,
                position: { lat: data.lat, lng: data.lng },
                title: data.name,
                //content:biPin.element,
                content: logoImg,
                gmpClickable: true,
            });
            // Add a click listener for each marker, and set up the info window.
            marker.addListener('click', () => {
                //const { target } = domEvent;
                this.infoWindow.close();
                this.infoWindow.setContent(`<b>${marker.title}</b></br>${data.address}</br>${data.phone_number}</br>${data.web_site}`);
                this.infoWindow.open(marker.map, marker);
            });
        });
    }
}
