import { app_config } from "../../app_config";
import { CustomElement } from "@nxcode-npm/custom_element";
import { Router } from "@nxcode-npm/app_router";
import { initializeApp } from "firebase/app";
import { App } from "../../app";
import { getFirestore, addDoc, collection } from "firebase/firestore";
export class IndexPage extends CustomElement {
    constructor() {
        super();
        this.refs = {
            btn_expl: null,
            btn_insert: null
        };
    }
    render() {
        super.render();
        this.setRefs();
        this.setEventListeners();
        this.initFirebaseApp();
        this.setAnalytics();
    }
    initFirebaseApp() {
        // Initialize Firebase
        const app = initializeApp(app_config.firebaseConfig);
        // Initialize Cloud Firestore and get a reference to the service
        //console.debug(app)
        App.state.db = getFirestore(app);
        //console.debug("db", (<State>App.state).db)
    }
    template() {
        return [`<nav-bar></nav-bar>`, `<div class="row h-100 gx-0 gy-sm-5 gy-lg-0 align-content-center justify-content-center">
            <div class="col">
                <div class="row g-0 justify-content-center">
                    <div class="col-auto align-content-center text-center"><img src="logo.png" with="120" height="120" /></div>
                    <div class="col-auto align-content-center text-center"><span class="display-1 fw-bold text-danger">${app_config.appName}</span>
                    <!--<br/><span class="fw-bold fs-4">We connect local businesses with job seekers</span>-->
                    </div>
                </div>
                <div class="row g-0 justify-content-center">
                    <div class="col-auto fs-4 text-center fw-bold px-3">
                    <!--We connect local businesses with job seekers through the Google map-->
                    Mettiamo in contatto le attivit&agrave; locali con chi cerca di lavoro sulla mappa di Google</div>
                </div>
                <div class="container px-4 my-4">
                    <div class="row gx-5 gy-5 justify-content-center">
                        <div class="col-sm-12 col-lg-4">
                            <div class="p-3 border bg-light text-center">
                                <div class="mb-3 fs-6">Cerchi lavoro come camerierə, cuocə, commessə ?</div>
                                <div><button ref="btn_expl" class="btn btn-dark"><i class="bi bi-compass-fill"></i> Esplora la mappa</button></div>
                            </div>    
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="p-3 border bg-light text-center">
                                <div class="mb-3 fs-6">Hai un' attivit&agrave; e cerchi nuovo personalə per il tuo staff ?</div>
                                <div><button ref="btn_insert" class="btn btn-danger"><i class="bi bi-geo-fill"></i> Aggiungi un <i>pin</i></button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`];
    }
    setEventListeners() {
        this.refs.btn_expl ?
            this.refs.btn_expl.onclick = (ev) => Router.nav({ key: "map_jobs" })
            : undefined;
        this.refs.btn_insert ?
            this.refs.btn_insert.onclick = (ev) => Router.nav({ key: "insert_job" })
            : undefined;
    }
    setAnalytics() {
        const data = { action: "app_enter", timestamp: new Date(Date.now()).toISOString() };
        addDoc(collection(App.state.db, "analytics_"), data).then((docRef) => {
            console.log(data);
        }).catch(err => {
            console.error(err);
        });
    }
}
